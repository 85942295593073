const API_PATH = {
	SHIPMENTS: "/v2/shipment",
	AUTO_CRUD_CUSTOM_FIELDS: "/v2/autocrud/custom_fields",
	FILTER: "/filter"
};

const URL_PATH = {
	DETAILS: "/shipments/details/",
	AUDIT: "/admin/audits",
	DISSECT_BASE_URL: "https://dissect.roambee.com",
	EDIT: "/shipments/edit/"
};

export { API_PATH, URL_PATH };
