/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { DataTable, Loader, SubHeader, withTooltip, IconButton, DrawerPanel } from "@roambee/client-styleguide";
// @ts-ignore
import { API, EventEmitter, generateQueryParams, getAuthUser } from "@roambee/client-utility";
import { API_PATH, URL_PATH } from "../configs/ShipmentConfig";
import { Shipment_Default_Filters, Shipment_Table_Columns, shipment_Table_Filter_Operations, defaultHiddenColumns } from "../configs/ShipmentTableConfigs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tooltip, Menu, MenuItem, ListItemIcon, ListItemText, Box } from "@mui/material";
import { Attachment, ChartLine, Checkmark, Close, Edit, Location, Renew, Report, Result, Share, Time, View, CopyLink, Download, OverflowMenuVertical, Settings } from "@carbon/icons-react";
import AllFilters from "./AllFilters";

let tableConfiguration: any = localStorage.getItem("shipment_table");
if (tableConfiguration) {
	try {
		tableConfiguration = JSON.parse(tableConfiguration);
	} catch (error) {
		console.error("Error parsing 'shipment_table' from localStorage:", error);
	}
}

const parseFilterString = (filterString) => {
	if (filterString && filterString.length) {
		return filterString?.split(" and ").map((filter) => {
			const [id, operator, value] = filter.match(/(\w+)\s(\w+)\s'(.+)'/).slice(1);
			// if operator is in, then return array of values
			if (operator === "in") {
				return { id, value: value.split(",") };
			} else {
				return { id, value };
			}
		});
	} else {
		return null;
	}
};

const generateFilterString = (filters) => {
	return filters
		.filter((filter) => filter.value !== null && filter.value !== undefined && filter.value.length !== 0)
		.map((filter) => {
			if (filter.value.isCombination) {
				// if _combinedFilter[Object.keys(_combinedFilter)[0]].value has var_{value}_var then replace var_{value}_var with {value}
				return filter.value.value
					.map((_combinedFilter) => {
						const value = _combinedFilter[Object.keys(_combinedFilter)[0]].value;
						if (value.includes("var_")) {
							return `${Object.keys(_combinedFilter)[0]} ${_combinedFilter[Object.keys(_combinedFilter)[0]].matchMode} '${value.replace(/var_(\w+)_var/g, (_match, p1) => p1)}'`;
						}
						return `${Object.keys(_combinedFilter)[0]} ${_combinedFilter[Object.keys(_combinedFilter)[0]].matchMode} '${value}'`;
					})
					.join(" and ");
			} else {
				const operations = shipment_Table_Filter_Operations[filter.id || filter.field];
				if (!operations) return "";
				// Split operations by '|' to handle multiple operations
				return operations
					.split("|")
					.map((operation) => `${filter.id || filter.field} ${operation} '${filter.value}'`)
					.join(" or ");
			}
		})
		.filter((filter) => filter !== "")
		.join(" and ");
};

function ShipmentTable() {
	const user = getAuthUser();
	let urlFilters: any = {};
	const [searchParams, setSearchParams] = useSearchParams();
	if (searchParams && searchParams.get("filter")) {
		try {
			urlFilters = JSON.parse(decodeURIComponent(searchParams.get("filter") || ""));
			console.info(urlFilters, "Parsed URL Filters");
		} catch (e) {
			console.error("Failed to parse JSON:", e);
		}
	}
	const [data, setData] = useState({ data: [], offset: 0, total: 0, size: 10 });
	const [customField, setCustomField] = useState([]);
	const [columnFilters, setColumnFilters] = useState(
		parseFilterString(urlFilters?.filter) ||
			parseFilterString(tableConfiguration?.filter) || [
				{ id: "parent_shipment", value: "false" },
				{ id: "active", value: "1" }
			]
	);
	const [columnFilterModes, setColumnsFilterModes] = useState();
	const [loading, setLoading] = useState(true);
	const [currentTab, setCurrentTab] = useState("");
	const [sorting, setSorting] = useState(urlFilters?.sort || tableConfiguration?.sort || []);
	const [pagination, setPagination] = useState({
		pageIndex: urlFilters?.offset / 10 || tableConfiguration?.offset / 10 || 0,
		pageSize: urlFilters?.size || tableConfiguration?.size || 10
	});
	const [columnPinning, setColumnPinning] = useState<any>(urlFilters?.columnPinning || tableConfiguration?.columnPinning || { left: [], right: ["actions"] });
	const [columnSizing, setColumnSizing] = useState<any>(urlFilters?.columnSizing || tableConfiguration?.columnSizing || {});
	const [columnOrdering, setColumnOrdering] = useState<any>(urlFilters?.columnOrdering || tableConfiguration?.columnOrdering || []);
	const [density, setDensity] = useState<any>(urlFilters?.density || tableConfiguration?.density || "comfortable");
	const [columnVisibility, setColumnVisibility] = useState<any>(urlFilters?.columnVisibility || tableConfiguration?.columnVisibility || defaultHiddenColumns);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openRowId, setOpenRowId] = useState(null);
	const [columnsReady, setColumnsReady] = useState(false); // Step 1: State variable

	// system filter
	const [open, setOpen] = useState(false);
	const [systemFilters, setSystemFilters] = useState([]);
	const [selectedSystemFilter, setSelectedSystemFilter] = useState(null);
	const [selectedDefaultFilter, setSelectedDefaultFilter] = useState(null);
	const defaultFilters = useMemo(() => Shipment_Default_Filters, []);
	const memoizedGenerateFilterString = useMemo(() => generateFilterString(columnFilters), [columnFilters]);

	const throttle = (func, limit) => {
		let lastFunc;
		let lastRan;
		return (...args) => {
			if (!lastRan) {
				func(...args);
				lastRan = Date.now();
			} else {
				clearTimeout(lastFunc);
				lastFunc = setTimeout(() => {
					if (Date.now() - lastRan >= limit) {
						func(...args);
						lastRan = Date.now();
					}
				}, limit - (Date.now() - lastRan));
			}
		};
	};

	const actionItemConfigs = useMemo(
		() => [
			{
				label: "Details",
				disabled: false,
				icon: <Result />,
				onClick: (row) => onDetails(row)
			},
			{
				label: "Locate",
				disabled: true,
				icon: <Location />,
				onClick: () => onShipmentLocate()
			},
			{
				label: "Report",
				disabled: true,
				icon: <Report />,
				onClick: () => onShipmentReport()
			},
			{
				label: "Attachments",
				disabled: true,
				icon: <Attachment />,
				onClick: () => onShipmentAttachments()
			},
			{
				label: "Share",
				disabled: true,
				icon: <Share />,
				onClick: () => onShare()
			},
			{
				label: "Audit",
				disabled: false,
				icon: <View />,
				onClick: (row) => onAudit(row)
			},
			{
				label: "Dissect",
				disabled: false,
				icon: <ChartLine />,
				onClick: (row) => redirectDissect(row)
			},
			{
				label: "Report Interval",
				disabled: true,
				icon: <Time />,
				onClick: () => onReportInterval()
			},
			{
				label: "Complete",
				disabled: true,
				icon: <Checkmark />,
				onClick: () => onComplete()
			},
			{
				label: "Replay",
				disabled: true,
				icon: <Renew />,
				onClick: () => onReplay()
			},
			{
				label: "Edit",
				disabled: false,
				icon: <Edit />,
				onClick: (row) => onShipmentUpdate(row)
			},
			{
				label: "Delete",
				disabled: true,
				icon: <Close />,
				onClick: () => onShipmentDelete()
			}
		],
		[]
	);

	const handleShipmentConfigure = () => {
		console.info("Shipment Configure Clicked!");
	};

	const actionColumn = useMemo(() => {
		return {
			id: "actions",
			header: "Actions",
			Header: (
				<Tooltip title="Actions">
					<div>
						<IconButton ariaLabel="Actions" icon={<Settings />} disabled={true} size="small" variant="no-bg" onClick={() => handleShipmentConfigure()} />
					</div>
				</Tooltip>
			),
			size: 60,
			columnDefType: "display",
			enablePinning: false,
			Cell: ({ row }) => {
				const isOpen = openRowId === row.id;
				return (
					<>
						<Box>
							<IconButton ariaLabel="Row actions" icon={<OverflowMenuVertical />} size="small" variant="no-bg" aria-controls={isOpen ? "actions-menu" : undefined} aria-haspopup="true" aria-expanded={isOpen ? "true" : undefined} onClick={(e) => handleClick(e, row.id)} />
						</Box>
						<Menu anchorEl={anchorEl} id="datable-Menu" open={isOpen} onClose={handleActionMenuClose} onClick={handleActionMenuClose} transformOrigin={{ horizontal: "right", vertical: "top" }} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
							{actionItemConfigs.map((actionMenuItem) => (
								<MenuItem key={actionMenuItem.label} onClick={() => actionMenuItem.onClick(row)} disabled={actionMenuItem.disabled}>
									<ListItemIcon>{actionMenuItem.icon}</ListItemIcon>
									<ListItemText primary={actionMenuItem.label} />
								</MenuItem>
							))}
						</Menu>
					</>
				);
			}
		};
	}, [anchorEl, openRowId, actionItemConfigs]);

	const columns = useMemo(() => [...Shipment_Table_Columns, actionColumn], [actionColumn]);

	const handleColumnPinningChange = (newColumnPinning) => {
		// Check if newColumnPinning is a function, and if so, call it to get the new state
		const updatedColumnPinning =
			typeof newColumnPinning === "function"
				? newColumnPinning(columnPinning) // call the function with current state
				: newColumnPinning;

		// Ensure the right array exists and 'actions' is appended to the end
		const right = updatedColumnPinning?.right ?? [];
		const updatedRight = right.filter((col) => col !== "actions"); // Remove 'actions' temporarily

		setColumnPinning({
			...updatedColumnPinning,
			right: [...updatedRight, "actions"] // Ensure 'actions' is always at the end
		});
	};

	const [headerButtonConfigs, setHeaderButtonConfigs] = useState([
		{
			tooltipText: "Copy Deep-Link",
			variant: "no-bg",
			size: "small",
			icon: <CopyLink />,
			onClick: () => handleCopyDeepLink()
		},
		{
			tooltipText: "Download Report",
			variant: "no-bg",
			size: "small",
			icon: <Download />,
			onClick: () => exportReport()
		}
	]);

	const handleClick = (event, rowId) => {
		setAnchorEl(event.currentTarget);
		setOpenRowId(rowId);
	};

	const handleActionMenuClose = () => {
		setAnchorEl(null);
		setOpenRowId(null);
	};

	const handleCopyDeepLink = () => {
		navigator.clipboard.writeText(window.location.origin + window.location.pathname + `?filter=${localStorage.getItem("shipment_table")}${localStorage.getItem("shipment_activeTab") ? "&activeTab=" + localStorage.getItem("shipment_activeTab") : ""}`);

		setHeaderButtonConfigs((prevConfigs) => {
			const updatedConfigs = [...prevConfigs];
			updatedConfigs.splice(0, 1, {
				tooltipText: "Link Copied!",
				variant: "no-bg",
				size: "small",
				icon: <Checkmark />,
				onClick: () => console.info("Link Copied!")
			});
			return updatedConfigs;
		});

		setTimeout(() => {
			setHeaderButtonConfigs((prevConfigs) => {
				const headerConfigs = [...prevConfigs];
				headerConfigs.splice(0, 1, {
					tooltipText: "Copy Deep-Link",
					variant: "no-bg",
					size: "small",
					icon: <CopyLink />,
					onClick: () => handleCopyDeepLink()
				});
				return headerConfigs;
			});
		}, 3500);
	};

	const handleFilterClick = useCallback(
		(tab) => {
			// Update columnFilters based on tab
			if (tab === "all") {
				// Remove filter with id: "active"
				setColumnFilters((prevFilters) => prevFilters.filter((filter) => filter.id !== "active"));
			} else {
				// Check if there is already a filter with id: "active"
				const existingFilterIndex = columnFilters.findIndex((filter) => filter.id === "active");

				if (existingFilterIndex !== -1) {
					// Update existing filter with id: "active"
					const updatedColumnFilters = [...columnFilters];
					updatedColumnFilters[existingFilterIndex].value = tab === "active" ? "1" : "0";
					setColumnFilters(updatedColumnFilters);
				} else {
					// Add new filter with id: "active"
					setColumnFilters((prevFilters) => [...prevFilters, { id: "active", value: tab === "active" ? "1" : "0" }]);
				}
			}
		},
		[columnFilters]
	); // Add 'columnFilters' as a dependency

	const addParamToUrl = useCallback(
		(key, value) => {
			searchParams.set(key, value);
			setSearchParams(searchParams);
		},
		[searchParams, setSearchParams]
	);

	const removeParamFromUrl = useCallback(
		(key) => {
			searchParams.delete(key);
			setSearchParams(searchParams);
		},
		[searchParams, setSearchParams]
	);

	const handleTabChange = useCallback(
		(key) => {
			if (key == "all") {
				setCurrentTab("all");
				removeParamFromUrl("activeTab");
				handleFilterClick("all");
				localStorage.removeItem("shipment_activeTab");
			} else {
				setCurrentTab(key);
				addParamToUrl("activeTab", key);
				handleFilterClick(key);
				localStorage.setItem("shipment_activeTab", key);
			}
		},
		[handleFilterClick, addParamToUrl, removeParamFromUrl]
	);

	const tabConfigs = useMemo(
		() => [
			{
				label: "All",
				active: currentTab === "all",
				onClick: () => {
					if (currentTab !== "all") {
						handleTabChange("all");
					}
				}
			},
			{
				label: "Active",
				active: currentTab === "active",
				onClick: () => {
					if (currentTab !== "active") {
						handleTabChange("active");
					}
				}
			},
			{
				label: "Completed",
				active: currentTab === "completed",
				onClick: () => {
					if (currentTab !== "completed") {
						handleTabChange("completed");
					}
				}
			}
		],
		[currentTab, handleTabChange]
	);

	const buttonConfigs = useMemo(
		() => [
			{
				label: "Create Shipment",
				variant: "primary",
				size: "small",
				disabled: true,
				onClick: () => console.info("Create Shipment Button Clicked")
			}
		],
		[]
	);

	useEffect(() => {
		// Fetch the previous state from local storage
		const getsavedTableconfig = JSON.parse(localStorage.getItem("shipment_table")) || {};
		const updatedTableConfig = {
			...getsavedTableconfig,
			columnPinning,
			columnSizing,
			columnOrdering,
			density,
			columnVisibility
		};
		// Save the updated state to local storage
		localStorage.setItem("shipment_table", JSON.stringify(updatedTableConfig));
	}, [columnPinning, columnSizing, columnOrdering, density, columnVisibility]);

	const getShipment = useCallback(() => {
		const activeTab = localStorage.getItem("shipment_activeTab") || null;
		const urlActiveTab = searchParams.get("activeTab") || null;
		if (activeTab) {
			if (activeTab == "active") {
				setCurrentTab("active");
				addParamToUrl("activeTab", "active");
			} else if (activeTab == "completed") {
				setCurrentTab("completed");
				addParamToUrl("activeTab", "completed");
			}
		} else if (urlActiveTab) {
			if (urlActiveTab == "active") {
				setCurrentTab("active");
				localStorage.setItem("shipment_activeTab", "active");
			} else if (urlActiveTab == "completed") {
				setCurrentTab("completed");
				localStorage.setItem("shipment_activeTab", "completed");
			}
		} else {
			if (currentTab !== "all") {
				handleTabChange("active");
			}
		}

		// Use the latest columnFilters directly here
		const params = {
			offset: pagination.pageIndex * 10 || 0,
			size: pagination.pageSize || 10,
			fields: "all",
			filter: memoizedGenerateFilterString, // Use the generated filter string
			sort: sorting
		};
		const queryString = generateQueryParams(params);
		setLoading(true);
		API("GET", `${API_PATH.SHIPMENTS}?${queryString}`)
			.then((result) => {
				const mergedData = result.data?.map((item) => ({
					...item,
					...item.custom_fields // Optionally spread custom_fields data directly into each item
				}));
				setData({
					...data, // Preserve existing properties like offset, total, size
					data: mergedData, // Update data property with mergedData
					offset: result?.offset,
					total: result?.total,
					size: result?.size
				});
				setLoading(false);
			})
			.catch((error) => {
				if (error.status === 404 && error.message) {
					console.error(error.message);
				} else {
					console.error("Other Error:", error);
					EventEmitter.emit("showSnackbar", {
						id: "error",
						leftIcon: true,
						message: error?.message || "Something went wrong!",
						variant: "error",
						ariaLabel: "close button",
						position: "top-right"
					});
					setLoading(false);
				}
			});
		localStorage.setItem("shipment_table", JSON.stringify(params));
	}, [columnFilters, sorting, pagination]); // Ensure columnFilters is included here

	const getNestedValue = (object, path) => {
		return path?.split(".").reduce((acc, key) => acc && acc[key], object);
	};

	useEffect(() => {
		const params = {
			rbql: {
				filters: [
					{ name: "account_id", value: user?.account?.id },
					{ name: "active", value: 1 },
					{ name: "type", values: ["shipment", "package"] }
				],
				other_options: { return_all: true }
			}
		};

		const queryString = generateQueryParams(params);
		API("GET", `${API_PATH.AUTO_CRUD_CUSTOM_FIELDS}?${queryString}`)
			.then((result) => {
				const apiColumns = result.data.map((item) => ({
					accessorKey: `custom_fields.${item.fieldname.toLowerCase()}`,
					Header: (
						<Tooltip title={item.fieldname}>
							<div>{item.fieldname}</div>
						</Tooltip>
					),
					header: item.fieldname,
					isSortable: true,
					isSearchable: true,
					filterVariant: item.datatype === "select" ? "select" : undefined,
					Cell: withTooltip(({ cell }) => {
						const value = getNestedValue(cell.row.original, cell.column.accessorKey);
						return <div>{value || "-"}</div>;
					}),
					filterSelectOptions: Array.isArray(item.values) ? JSON.parse(item.values).map((val) => val.value) : undefined
				}));
				setCustomField(apiColumns);
				setLoading(false);
			})
			.catch((error) => {
				console.error("API Error:", error);
				EventEmitter.emit("showSnackbar", {
					id: "error",
					leftIcon: true,
					message: error?.message || "Something went wrong!",
					variant: "error",
					ariaLabel: "close button",
					position: "top-right"
				});
				setLoading(false);
			});
	}, [user]);

	const combinedColumns = useMemo(() => {
		const cols = [...columns, ...customField];
		setColumnsReady(true); // Step 2: Set to true when columns are combined
		return cols;
	}, [columns, customField]);

	useEffect(() => {
		getShipment();
	}, [getShipment]);

	useEffect(() => {
		const queryString = generateQueryParams({ entity: "SHIPMENT" });
		API("GET", `${API_PATH.FILTER}?${queryString}`)
			.then((result) => {
				const _systemFilters = result.data.map((f) => {
					return { ...f, label: f.name };
				});
				setSystemFilters(_systemFilters);
			})
			.catch((error) => {
				console.error("API Error:", error);
				EventEmitter.emit("showSnackbar", {
					id: "error",
					leftIcon: true,
					message: error?.message || "Something went wrong!",
					variant: "error",
					ariaLabel: "close button",
					position: "top-right"
				});
			});
	}, []);

	useEffect(() => {
		const savedDefaultFilter = localStorage.getItem("shipment_default_filter");
		if (savedDefaultFilter) {
			setSelectedDefaultFilter(JSON.parse(savedDefaultFilter));
		}
	}, [defaultFilters]);

	useEffect(() => {
		const savedSystemFilter = localStorage.getItem("shipment_system_filter");
		if (savedSystemFilter) {
			setSelectedSystemFilter(JSON.parse(savedSystemFilter));
		}
	}, [defaultFilters]);

	const onDetails = (row) => {
		const BASE_URL = process.env.CLIENT_HC10_URL;
		window.open(BASE_URL + URL_PATH.DETAILS + row.original.uuid);
	};

	const onShipmentLocate = () => {
		return;
	};

	const onShipmentReport = () => {
		return;
	};

	const onShipmentAttachments = () => {
		return;
	};

	const onShare = () => {
		return;
	};

	const onAudit = (row) => {
		const BASE_URL = process.env.CLIENT_HC10_URL;
		const params = {};
		const userAccount = getAuthUser().account;
		params["account_id"] = userAccount.id;
		params["entity_uuid"] = row.original.uuid;
		params["entity_type"] = "SHIPMENTS";
		params["entity_name"] = row.original.shipment_name;
		params["entity_account_name"] = row.original.account_name;

		window.open(BASE_URL + URL_PATH.AUDIT + "?" + generateQueryParams(params));
	};

	const redirectDissect = (row) => {
		const host = process.env.CLIENT_HC10_URL;
		if (host.includes("staging")) {
			window.open(URL_PATH.DISSECT_BASE_URL.replace("dissect", "dissect-staging") + "/multiple_pages?shipment_uuid=" + row.original.uuid, "_blank");
		} else {
			window.open(URL_PATH.DISSECT_BASE_URL + "/multiple_pages?shipment_uuid=" + row.original.uuid, "_blank");
		}
	};

	const onReportInterval = () => {
		return;
	};

	const onComplete = () => {
		return;
	};

	const onReplay = () => {
		return;
	};

	const onShipmentUpdate = (row) => {
		const BASE_URL = process.env.CLIENT_HC10_URL;
		window.open(BASE_URL + URL_PATH.EDIT + row.original.uuid);
	};

	const onShipmentDelete = () => {
		return;
	};

	const exportReport = () => {
		// Prepare query parameters
		const params = {
			$fields: "all",
			$filter: memoizedGenerateFilterString,
			timezone: "Asia/Kolkata", // Adjust timezone if needed
			distance_pref: getAuthUser()?.settings[3]?.value || "metric",
			$order_by: sorting,
			"report-type": "xls",
			a_t: Math.floor(Date.now() / 1000) // Ensure all required parameters are included
		};
		// Convert params to query string
		const queryString = generateQueryParams(params);

		window.open(process.env.CLIENT_HC10_URL + API_PATH.SHIPMENTS + "?" + queryString);
		// Optionally, set loading state here if needed
		setLoading(true);
		// Stop showing loading state after a timeout (adjust as necessary)
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	const handleAllFilters = () => {
		setOpen(true);
	};

	const handleSaveFilters = (selectedFilters) => {
		console.info("Save Filters:", selectedFilters);
		// selectedFilters is array of [{accessorKey: "abc"}]
		// columnFilters is array of [{id: "abc", value: "def"}]
		// find columnFilters where columnFilter.id is selectedFilter.accessorKey
		const appliedFilters = [];
		selectedFilters.forEach((filter) => {
			// find filter in columnFilters where columnFilter.id is filter.accessorKey
			const columnFilter = columnFilters.find((c) => c.id === filter.accessorKey);
			if (columnFilter) {
				// decide value based on filterVariant
				if (filter.filterVariant === "select") {
					appliedFilters.push({ ...filter, value: { label: columnFilter.value, value: columnFilter.value } });
				} else if (filter.filterVariant === "multi-select") {
					appliedFilters.push({ ...filter, value: columnFilter.value.map((v) => ({ label: v, value: v })) });
				} else {
					appliedFilters.push({ ...filter, value: columnFilter.value });
				}
			}
		});
		console.info("Applied Filters:", appliedFilters);

		combinedColumns.forEach((column) => {
			const appliedFilter = appliedFilters.find((f) => f.accessorKey === column.accessorKey);
			if (appliedFilter) {
				column.value = appliedFilter.value;
			}
		});

		setOpen(true);
	};

	const handleSystemFilters = (event, selectedFilter) => {
		if (selectedFilter && selectedFilter.filterJson && selectedFilter.filterJson.filters) {
			setSelectedSystemFilter(selectedFilter);

			// apply filter to the grid
			// get filter json and append into columnFilters
			const filters = selectedFilter.filterJson.filters;
			// Merge filters without duplicates
			const desiredFilters = [
				{ id: "parent_shipment", value: "false" },
				{ id: "active", value: "1" }
			];

			// Check if any filter has field equal to "status"
			const hasStatusField = filters.some((filter) => filter.field === "status");

			// Create mergedFilters with existing filters and desired filters if not present
			const mergedFilters = [
				...filters,
				...desiredFilters.filter(
					(desired) => !filters.some((filter) => filter.id === desired.id) && !(desired.id === "active" && hasStatusField) // Check for status field
				)
			];
			// const uniqueCombinedFilters = Array.from(
			// 	new Map(mergedFilters.map(filter => [filter.id, filter])).values()
			// );
			// Format mergedFilters as needed
			// const formattedFilters = uniqueCombinedFilters.map((filter) => ({
			// 	id: filter.field || filter.id,
			// 	value: filter.value
			// }));
			// console.log(formattedFilters, 'formattedFilters')
			setColumnFilters(mergedFilters);
			// store selectedFilter id into localStorage
			selectedFilter = {
				...selectedFilter,
				label: selectedFilter.name
			};
			localStorage.setItem("shipment_system_filter", JSON.stringify(selectedFilter));
		} else {
			// remove selectedSystemFilter from columnFilters
			setColumnFilters((prevFilters) => prevFilters.filter((filter) => !selectedSystemFilter.filterJson.filters.some((sysFilter) => sysFilter.field === filter.id)));
			setSelectedSystemFilter(null);
			// remove selectedFilter id from localStorage
			localStorage.removeItem("shipment_system_filter");
		}
	};

	const handleDefaultFilters = (event, selectedFilter) => {
		console.info("Default Filter:", selectedFilter);

		if (selectedFilter) {
			setSelectedDefaultFilter(selectedFilter);
			// apply filter to the grid
			// get filter json and append into columnFilters
			const filters = [selectedFilter];
			// Merge filters without duplicates
			const mergedFilters = [...filters.filter((filter) => !columnFilters.some((colFilter) => colFilter.id === filter.field))];

			// Format mergedFilters as needed
			const formattedFilters = mergedFilters.map((filter) => ({
				id: filter.field || filter.id,
				value: filter.value
			}));

			setColumnFilters(formattedFilters);
			// store selectedFilter id into localStorage
			localStorage.setItem("shipment_default_filter", JSON.stringify(selectedFilter));
		} else {
			setSelectedDefaultFilter(null);
			localStorage.removeItem("shipment_default_filter");
		}
	};

	const handleFilterApplied = (filter) => {
		console.info("Filter applied:", filter);
		// add filter into systemFilters when add filter is an object
		setSystemFilters((prevFilters) => [...prevFilters, { ...filter, label: filter.name }]);

		handleSystemFilters(null, { ...filter, label: filter.name });
		setOpen(false);
	};

	const handleCancelFilter = () => {
		setOpen(false);
	};

	return (
		<section id="shipment-table">
			{loading && <Loader />}
			<SubHeader tabConfigs={tabConfigs} buttonConfigs={buttonConfigs} currentTab={currentTab} onTab={setCurrentTab} />
			<DataTable
				title="Shipment"
				isSelectable={false}
				columnFilters={columnFilters}
				onColumnFiltersChange={setColumnFilters}
				rowCount={data.total}
				columns={columnsReady ? combinedColumns : []}
				data={data?.data || []}
				isLoading={loading}
				columnFilterFns={columnFilterModes}
				onColumnFilterFnsChange={setColumnsFilterModes}
				sorting={sorting}
				onSortingChange={setSorting}
				onPaginationChange={setPagination}
				onColumnPinningChange={handleColumnPinningChange}
				pagination={pagination}
				columnPinning={columnPinning}
				headerButtonConfigs={headerButtonConfigs}
				onColumnSizingChange={setColumnSizing}
				columnSizing={columnSizing}
				onColumnOrderChange={setColumnOrdering}
				columnOrder={columnOrdering}
				density={density}
				onDensityChange={setDensity}
				onColumnVisibilityChange={setColumnVisibility}
				columnVisibility={columnVisibility}
				systemFilters={systemFilters}
				onSystemFilters={handleSystemFilters}
				selectedSystemFilter={selectedSystemFilter}
				defaultFilters={defaultFilters}
				selectedDefaultFilter={selectedDefaultFilter}
				onAllFilters={handleAllFilters}
				onSaveFilters={handleSaveFilters}
				onDefaultFilters={handleDefaultFilters}
				setSelectedSystemFilter={setSelectedSystemFilter}
			/>
			{open && (
				<DrawerPanel open={open} setOpen={setOpen} title="Filter">
					<AllFilters allowAI={true} systemFilters={systemFilters} selectedFilter={selectedSystemFilter} filters={combinedColumns.filter((c) => c.isSearchable)} onFilterApplied={handleFilterApplied} onCancelFilter={handleCancelFilter} />
				</DrawerPanel>
			)}
		</section>
	);
}

export default ShipmentTable;
