/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { formatDateTime, getDistanceConversion, getTemperatureConversion } from "@roambee/client-utility";
// @ts-ignore
import { withTooltip, DefaultCell } from "@roambee/client-styleguide";

// @ts-ignore
import { Tooltip } from "@mui/material";

const getLastGeoAlert = () => {
	// TODO : get this from rb_of
	return ["MOVING", "MOVINGAGAIN", "STOPPED", "STILLSTOPPED", "SHIPMENTSTART"];
};

// Shipment table columns
const Shipment_Table_Columns = [
	{
		Header: (
			<Tooltip title="Account Name" placement="top">
				<div>Account Name</div>
			</Tooltip>
		),
		header: "Account Name",
		accessorKey: "account_name",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Wrap the default cell with tooltip
	},
	{
		header: "Name",
		accessorKey: "shipment_name",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (
			<a href={`${process.env.CLIENT_HC10_URL}/shipments/details/${cell.row.original.uuid}`} target="_blank" rel="noopener noreferrer">
				{cell.getValue() ? cell.getValue() : "-"}
			</a>
		)) // Custom cell with tooltip
	},
	{
		header: "Type",
		accessorKey: "shipment_type",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Bee Name",
		accessorKey: "bee_name",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Bee IMEI",
		accessorKey: "bee_imei",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Origin",
		accessorKey: "orgi_location_name",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Origin Detailed Name",
		Header: (
			<Tooltip title="Orgin Detailed Name" placement="top">
				<div>Orgin Detailed Name</div>
			</Tooltip>
		),
		accessorKey: "orgi_detailed_name",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Multi Modal Mode",
		Header: (
			<Tooltip title="Multi Modal Mode" placement="top">
				<div>Multi Modal Mode</div>
			</Tooltip>
		),
		accessorKey: "multi_modal_mode",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Destination",
		Header: (
			<Tooltip title="Destination" placement="top">
				<div>Destination</div>
			</Tooltip>
		),
		accessorKey: "desti_location_name",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Destination Detailed Name",
		Header: (
			<Tooltip title="Destination Detailed Name" placement="top">
				<div>Destination Detailed Name</div>
			</Tooltip>
		),
		accessorKey: "desti_detailed_name",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Created Date",
		Header: (
			<Tooltip title="Created Date" placement="top">
				<div>Created Date</div>
			</Tooltip>
		),
		accessorKey: "created_date_timestamp",
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => <span>{cell.getValue() ? formatDateTime(cell.getValue(), "LLL dd, yyyy hh:mm a") : "-"}</span>) // Custom date cell with tooltip
	},
	{
		header: "Departure",
		accessorKey: "actual_departure",
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => <span>{cell.getValue() ? formatDateTime(cell.getValue(), "LLL dd, yyyy hh:mm a") : "-"}</span>) // Custom departure cell with tooltip
	},
	{
		header: "Arrival",
		accessorKey: "scheduled_arrival_timestamp",
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => <span>{cell.getValue() ? formatDateTime(cell.getValue(), "LLL dd, yyyy hh:mm a") : "-"}</span>) // Custom arrival cell with tooltip
	},
	{
		header: "Google ETA",
		accessorKey: "google_eta",
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Predictive ETA",
		accessorKey: "prediction_eta",
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Battery",
		accessorKey: "battery_days",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => <span>{cell.getValue() ? `${cell.getValue()} d` : "-"}</span>) // Custom battery cell with tooltip
	},
	{
		header: "Status",
		accessorKey: "shipment_status",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Last Known Location",
		Header: (
			<Tooltip title="Last Known Location" placement="top">
				<div>Last Known Location</div>
			</Tooltip>
		),
		accessorKey: "last_known_location",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Last Known Times",
		Header: (
			<Tooltip title="Last Known Times" placement="top">
				<div>Last Known Times</div>
			</Tooltip>
		),
		accessorKey: "last_known_timestamp",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => <span>{cell.getValue() ? formatDateTime(cell.getValue(), "LLL dd, yyyy hh:mm a") : "-"}</span>) // Custom last known times cell with tooltip
	},
	{
		header: "Last Geo Alert",
		Header: (
			<Tooltip title="Last Geo Alert" placement="top">
				<div>Last Geo Alert</div>
			</Tooltip>
		),
		accessorKey: "last_geo_alert",
		isSortable: true,
		isSearchable: true,
		filterVariant: "multi-select",
		filterSelectOptions: getLastGeoAlert(),
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Temp",
		accessorKey: "temp",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => {
			const value = cell.getValue();
			return value ? getTemperatureConversion(value) : "-"; // Apply getTemperatureConversion
		})
	},
	{
		header: "MKT",
		accessorKey: "mkt",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Light",
		accessorKey: "light_state",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Pressure",
		accessorKey: "pressure",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Humidity",
		accessorKey: "humidity",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Ambient",
		accessorKey: "ambient",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Tilt",
		accessorKey: "tilt_shock",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Altitude",
		accessorKey: "altitude",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Tamper",
		accessorKey: "tamper",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Shock",
		accessorKey: "shock",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Distance Travelled",
		Header: (
			<Tooltip title="Distance Travelled" placement="top">
				<div>Distance Travelled</div>
			</Tooltip>
		),
		accessorKey: "distance_traveled",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => {
			const value = cell.getValue();
			return value ? getDistanceConversion(value) : "-"; // Apply getDistanceConversion
		})
	},
	{
		header: "Total Distance",
		Header: (
			<Tooltip title="Total Distance" placement="top">
				<div>Total Distance</div>
			</Tooltip>
		),
		accessorKey: "total_distance",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => {
			const value = cell.getValue();
			return value ? getDistanceConversion(value) : "-"; // Apply getDistanceConversion
		})
	},
	{
		header: "Transits Time",
		Header: (
			<Tooltip title="Transits Time" placement="top">
				<div>Transits Time</div>
			</Tooltip>
		),
		accessorKey: "in_transits",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Terminated On",
		Header: (
			<Tooltip title="Terminated On" placement="top">
				<div>Terminated On</div>
			</Tooltip>
		),
		accessorKey: "terminated_on",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => <span>{formatDateTime(cell.getValue(), "LLL dd, yyyy hh:mm a")}</span>) // Custom terminated on cell with tooltip
	},
	{
		header: "Distance to Destination",
		Header: (
			<Tooltip title="Distance to Destination" placement="top">
				<div>Distance to Destination</div>
			</Tooltip>
		),
		accessorKey: "distance_to_destination",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => {
			const value = cell.getValue();
			return value ? getDistanceConversion(value) : "-"; // Apply getDistanceConversion
		})
	},
	{
		header: "Termination Reason",
		Header: (
			<Tooltip title="Termination Reason" placement="top">
				<div>Termination Reason</div>
			</Tooltip>
		),
		accessorKey: "termination_reason",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "High Temperature Threshold",
		Header: (
			<Tooltip title="High Temperature Threshold" placement="top">
				<div>High Temperature Threshold</div>
			</Tooltip>
		),
		accessorKey: "high_temperature_threshold",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => {
			const value = cell.getValue();
			return value ? getTemperatureConversion(value) : "-"; // Apply getTemperatureConversion
		})
	},
	{
		header: "Highest Temperature Reached",
		Header: (
			<Tooltip title="Highest Temperature Reached" placement="top">
				<div>Highest Temperature Reached</div>
			</Tooltip>
		),
		accessorKey: "highest_temperature_reached",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => {
			const value = cell.getValue();
			return value ? getTemperatureConversion(value) : "-"; // Apply getTemperatureConversion
		})
	},
	{
		header: "Date w.r.t Highest Temperature",
		Header: (
			<Tooltip title="Date w.r.t Highest Temperature" placement="top">
				<div>Date w.r.t Highest Temperature</div>
			</Tooltip>
		),
		accessorKey: "highest_temperature_date",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Longest Duration Over High Temperature Threshold",
		Header: (
			<Tooltip title="Longest Duration Over High Temperature Threshold" placement="top">
				<div>Longest Duration Over High Temperature Threshold</div>
			</Tooltip>
		),
		accessorKey: "high_param_duration",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Reading Above High Temperature Threshold",
		Header: (
			<Tooltip title="Reading Above High Temperature Threshold" placement="top">
				<div>Reading Above High Temperature Threshold</div>
			</Tooltip>
		),
		accessorKey: "reading_above_high_param",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Temperature Standard Deviation",
		Header: (
			<Tooltip title="Temperature Standard Deviation" placement="top">
				<div>Temperature Standard Deviation</div>
			</Tooltip>
		),
		accessorKey: "temp_standard_deviation",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Low Temperature Threshold",
		Header: (
			<Tooltip title="Low Temperature Threshold" placement="top">
				<div>Low Temperature Threshold</div>
			</Tooltip>
		),
		accessorKey: "low_temperature_threshold",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => {
			const value = cell.getValue();
			return value ? getTemperatureConversion(value) : "-"; // Apply getTemperatureConversion
		})
	},
	{
		header: "Lowest Temperature Reached",
		Header: (
			<Tooltip title="Lowest Temperature Reached" placement="top">
				<div>Lowest Temperature Reached</div>
			</Tooltip>
		),
		accessorKey: "lowest_temperature_reached",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => {
			const value = cell.getValue();
			return value ? getTemperatureConversion(value) : "-"; // Apply getTemperatureConversion
		})
	},
	{
		header: "Date w.r.t Lowest Temperature",
		Header: (
			<Tooltip title="Date w.r.t Lowest Temperature" placement="top">
				<div>Date w.r.t Lowest Temperature</div>
			</Tooltip>
		),
		accessorKey: "lowest_temperature_date",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Longest Duration Over Low Temperature Threshold",
		Header: (
			<Tooltip title="Longest Duration Over Low Temperature Threshold" placement="top">
				<div>Longest Duration Over Low Temperature Threshold</div>
			</Tooltip>
		),
		accessorKey: "low_param_duration",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(DefaultCell) // Use the default cell with tooltip
	},
	{
		header: "Reading Below Low Temperature Threshold",
		Header: (
			<Tooltip title="Reading Below Low Temperature Threshold" placement="top">
				<div>Longest Duration Over Low Temperature Threshold</div>
			</Tooltip>
		),
		accessorKey: "reading_below_low_param",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => {
			const value = cell.getValue();
			return value ? getTemperatureConversion(value) : "-"; // Apply getTemperatureConversion
		})
	}
];

const shipment_Table_Filter_Operations = {
	parent_shipment: "exists",
	active: "eq",
	account_name: "has",
	shipment_name: "has",
	shipment_type: "has",
	bee_name: "has",
	bee_imei: "has",
	orgi_location_name: "has",
	orgi_detailed_name: "has",
	multi_modal_mode: "has",
	desti_location_name: "has",
	desti_detailed_name: "has",
	created_date_timestamp: "has",
	actual_departure: "has",
	scheduled_arrival_timestamp: "has",
	google_eta: "has",
	prediction_eta: "has",
	battery_days: "has",
	shipment_status: "has",
	last_known_location: "has",
	last_known_timestamp: "has",
	last_geo_alert: "has",
	temp: "has",
	mkt: "has",
	light_state: "has",
	pressure: "has",
	humidity: "has",
	ambient: "has",
	tilt_shock: "has",
	altitude: "has",
	tamper: "has",
	shock: "has",
	distance_traveled: "has",
	total_distance: "has",
	in_transits: "has",
	terminated_on: "has",
	distance_to_destination: "has",
	termination_reason: "has",
	high_temperature_threshold: "has",
	highest_temperature_reached: "has",
	highest_temperature_date: "has",
	high_param_duration: "has",
	reading_above_high_param: "has",
	temp_standard_deviation: "has",
	low_temperature_threshold: "has",
	lowest_temperature_reached: "has",
	lowest_temperature_date: "has",
	low_param_duration: "has",
	reading_below_low_param: "has"
	// Add more column mappings here
};
const Shipment_Default_Filters = [
	{
		label: "Status",
		options: [
			{
				label: "Not Started",
				value: {
					isCombination: true,
					value: [
						{
							actual_departure: { value: "var_false_var", matchMode: "exists" }
						}
					]
				}
			},
			{
				label: "Terminated",
				value: {
					isCombination: true,
					value: [{ active: { value: "0", matchMode: "eq" } }, { shipment_status: { value: "Terminated|terminated|not started", matchMode: "eq" } }]
				}
			},
			{
				label: "Moving",
				value: {
					isCombination: true,
					value: [{ active: { value: "1", matchMode: "eq" } }, { shipment_status: { value: "Moving", matchMode: "eq" } }]
				}
			},
			{
				label: "Stopped",
				value: {
					isCombination: true,
					value: [{ active: { value: "1", matchMode: "eq" } }, { shipment_status: { value: "Stopped", matchMode: "eq" } }]
				}
			},
			{
				label: "Running",
				value: {
					isCombination: true,
					value: [{ active: { value: "1", matchMode: "eq" } }, { shipment_status: { value: "running", matchMode: "has" } }]
				}
			},
			{
				label: "Running Early",
				value: {
					isCombination: true,
					value: [{ active: { value: "1", matchMode: "eq" } }, { shipment_status: { value: "early", matchMode: "has" } }]
				}
			},
			{
				label: "Running On Time",
				value: {
					isCombination: true,
					value: [{ active: { value: "1", matchMode: "eq" } }, { shipment_status: { value: "on time", matchMode: "has" } }]
				}
			},
			{
				label: "Running Late",
				value: {
					isCombination: true,
					value: [{ active: { value: "1", matchMode: "eq" } }, { shipment_status: { value: "late", matchMode: "has" } }]
				}
			},
			{
				label: "Completed Early",
				value: {
					isCombination: true,
					value: [{ active: { value: "0", matchMode: "eq" } }, { actual_arrival: { value: "var_true_var", matchMode: "exists" } }, { actual_arrival: { value: "var_scheduled_arrival_var", matchMode: "lt" } }, { shipment_status: { value: "early", matchMode: "has" } }]
				}
			},
			{
				label: "Completed On Time",
				value: {
					isCombination: true,
					value: [{ active: { value: "0", matchMode: "eq" } }, { actual_arrival: { value: "var_true_var", matchMode: "exists" } }, { shipment_status: { value: "on time", matchMode: "has" } }]
				}
			},
			{
				label: "Completed Late",
				value: {
					isCombination: true,
					value: [{ active: { value: "0", matchMode: "eq" } }, { actual_arrival: { value: "var_true_var", matchMode: "exists" } }, { actual_arrival: { value: "var_scheduled_arrival_var", matchMode: "gt" } }, { shipment_status: { value: "late", matchMode: "has" } }]
				}
			}
		]
	}
];

const defaultHiddenColumns = {
	prediction_eta: false,
	battery_days: false,
	last_known_location: false,
	last_geo_alert: false,
	temp: false,
	light_state: false,
	humidity: false,
	ambient: false,
	altitude: false,
	distance_traveled: false,
	termination_reason: false,
	high_temperature_threshold: false,
	highest_temperature_date: false,
	high_param_duration: false,
	reading_above_high_param: false,
	temp_standard_deviation: false,
	low_temperature_threshold: false,
	lowest_temperature_reached: false
};

export { Shipment_Table_Columns, shipment_Table_Filter_Operations, defaultHiddenColumns, Shipment_Default_Filters };
